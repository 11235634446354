import { SIGN_IN, SIGN_UP } from 'src/common/constants/localizations/auth';
import { SUBSCRIBE_PAYMENT_FLOW_PROGRESS_BAR_STEP_LABEL } from 'src/common/constants/localizations/payment';
import viewTypes from '../../../constants/auth/viewTypes';
import { subFlowTypes } from '../../../constants/subscription/flow';

export default function getProgressBarSteps(
  showProgressBar,
  isPaymentFlow,
  viewType,
  subFlowType,
  getLocalizedText,
  isPairingFlow,
) {
  const hasProgressBar = isPaymentFlow && showProgressBar && !isPairingFlow;
  if (!hasProgressBar) {
    return null;
  }

  return [
    {
      label:
        viewType === viewTypes.signIn
          ? getLocalizedText(SIGN_IN)
          : getLocalizedText(SIGN_UP),
      active: true,
    },
    {
      label: getLocalizedText(SUBSCRIBE_PAYMENT_FLOW_PROGRESS_BAR_STEP_LABEL),
      active: subFlowType === subFlowTypes.subscribe,
    },
  ];
}
