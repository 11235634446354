import classnames from 'clsx';
import PropTypes from 'prop-types';
import css from './payment-flow-progress-bar.module.scss';

const PaymentFlowProgressBar = ({ steps }) => (
  <div
    data-testid="paymentFlowProgressBar"
    className={css.paymentFlowProgressBar}
  >
    {steps.map(({ label, active }, index) => {
      const stepNumber = index + 1;
      return (
        <div
          key={label}
          data-testid={`progressBarStep-${index}`}
          className={classnames(css.paymentFlowProgressBarStep, {
            [css.active]: active,
          })}
        >
          {`${stepNumber}. ${label}`}
        </div>
      );
    })}
  </div>
);

PaymentFlowProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PaymentFlowProgressBar;
