import classnames from 'clsx';
import interpolateComponents from 'interpolate-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchProfileIfInQueryByLocation } from 'src/common/actions/subscriptionFlow';
import cssVariables from 'src/common/styles/variables';
import billingFrequency from 'src/common/utils/subscription/billingPeriod';
import ImageWithDefault from '../shared/ImageWithDefault';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';
import {
  isCustomSourceEnabled,
  isDiscountEnabled,
} from '../utils/subscriptionProductDetails';

import { RADIOMILL_CMS_CUSTOM_ASSET_URL } from '../../constants/cmsLinks';
import {
  PAYMENT_FLOW_FORM_LAYOUT_UPSELL_MONTHLY,
  PAYMENT_FLOW_FORM_LAYOUT_UPSELL_MONTHLY_NO_TRIAL,
  PAYMENT_FLOW_FORM_LAYOUT_UPSELL_YEARLY,
  PAYMENT_FLOW_FORM_LAYOUT_UPSELL_YEARLY_NO_TRIAL,
} from '../../constants/localizations/payment';
import {
  LINE_BREAK_INTERPOLATE,
  PREMIUM,
} from '../../constants/localizations/shared';
import {
  ONE_TIME_DEFAULT_COUPON_FOOTNOTE_TEXT,
  UPSELL_COMMERCIAL_FREE_AUDIOBOOKS,
  UPSELL_COMMERCIAL_FREE_MUSIC,
  UPSELL_COMMERCIAL_FREE_NEWS,
  UPSELL_COMMERCIAL_FREE_SPORTS,
  UPSELL_LESS_ADS,
  UPSELL_MORERADIO_LESSADS_GOPREMIUM,
  UPSELL_NOCREDITCARD_REQUIRED,
} from '../../constants/localizations/upsell';
import { stripeDurationTypes } from '../../constants/subscription/coupon';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectCoupon } from '../../selectors/subscription';
import generateOriginLabelFromQuery from '../../utils/generateOriginLabelFromQuery';
import css from './upsell-card.module.scss';

// exported for testing
export const svgProps = {
  width: '13px',
  height: '10px',
  viewBox: '0 0 13 11',
  fill: cssVariables['--tunein-coral'],
};

export class UpsellCard extends Component {
  static propTypes = {
    selectedProduct: PropTypes.object,
    coupon: PropTypes.object,
    isNoPaymentFlow: PropTypes.bool,
    guideItem: PropTypes.object,
    containerStyles: PropTypes.object,
    actions: PropTypes.object,
  };

  static contextType = LocationAndLocalizationContext;

  static defaultProps = {
    containerStyles: {},
  };

  componentDidMount() {
    this.props.actions.fetchProfileIfInQueryByLocation(this.context.location);
  }

  isOneTimeDefaultCoupon() {
    const { selectedProduct, coupon } = this.props;
    return (
      selectedProduct?.isUsingDefaultCoupon &&
      coupon?.durationType === stripeDurationTypes.once
    );
  }

  getDiscountHtml() {
    const { selectedProduct } = this.props;
    const {
      billingPeriod,
      hasTrial,
      standardProductPrice,
      price,
      freeTrialLength,
    } = selectedProduct || {};
    const { getLocalizedText } = this.context;
    const discountedTitleClassNames = classnames(
      css.discountPriceText,
      css.topMargin,
    );

    let productString =
      billingPeriod === billingFrequency.yearly
        ? getLocalizedText(PAYMENT_FLOW_FORM_LAYOUT_UPSELL_YEARLY)
        : getLocalizedText(PAYMENT_FLOW_FORM_LAYOUT_UPSELL_MONTHLY);

    if (!hasTrial) {
      productString =
        billingPeriod === billingFrequency.yearly
          ? getLocalizedText(PAYMENT_FLOW_FORM_LAYOUT_UPSELL_YEARLY_NO_TRIAL)
          : getLocalizedText(PAYMENT_FLOW_FORM_LAYOUT_UPSELL_MONTHLY_NO_TRIAL);
    }

    let formattedPrice = price;

    if (this.isOneTimeDefaultCoupon()) {
      formattedPrice = `${price}*`;
    }

    return (
      <div data-testid="discountedTitle" className={discountedTitleClassNames}>
        {interpolateComponents({
          mixedString: productString,
          components: {
            price: (
              <h4 data-testid="fullPrice" className={css.strikeThroughPrice}>
                {standardProductPrice}
              </h4>
            ),
            discountedPrice: (
              <span
                data-testid="discountedPrice"
                className={css.upsellDiscountPrice}
              >
                {formattedPrice}
              </span>
            ),
            trial: (
              <span data-testid="trial" className={css.upsellTrialLength}>
                {freeTrialLength}
              </span>
            ),
          },
        })}
      </div>
    );
  }

  getStandardLayoutText() {
    const { isNoPaymentFlow } = this.props;
    const { getLocalizedText } = this.context;

    let titleKey = UPSELL_MORERADIO_LESSADS_GOPREMIUM;
    if (isNoPaymentFlow) {
      titleKey = UPSELL_NOCREDITCARD_REQUIRED;
    }

    const title = getLocalizedText(titleKey).replace(
      /\{0\}/gi,
      LINE_BREAK_INTERPOLATE,
    );
    return (
      <div
        data-testid="paymentFlowFormUpsellTitleContainer"
        className={css.topMargin}
      >
        <span
          data-testid="paymentFlowFormUpsellTitle"
          className={css.upsellTitle}
        >
          {interpolateComponents({
            mixedString: title,
            components: {
              linebreak: <br />,
            },
          })}
        </span>
      </div>
    );
  }

  getStationSpecificContent() {
    const { guideItem } = this.props;
    return (
      <div className={css.profileDetails}>
        <ImageWithDefault
          dataTestId="guideItemImage"
          src={guideItem.image}
          alt={guideItem?.accessibilityTitle || guideItem?.title}
        />
        <span>{guideItem.title}</span>
      </div>
    );
  }

  isProductDiscounted() {
    const { selectedProduct, isNoPaymentFlow } = this.props;

    if (!selectedProduct || isNoPaymentFlow) {
      return false;
    }

    const { location } = this.context;
    const { hasPriceDiscount, hasCouponBeenApplied } = selectedProduct;
    // if it's a discount flow OR if there is a coupon
    const hasProductDiscount =
      hasCouponBeenApplied || isDiscountEnabled(location);

    return hasProductDiscount && hasPriceDiscount;
  }

  render() {
    const { getLocalizedText, location } = this.context;
    const { guideItem } = this.props;
    const source = generateOriginLabelFromQuery(location);

    let Header;
    if (guideItem) {
      Header = this.getStationSpecificContent();
    } else if (this.isProductDiscounted()) {
      Header = this.getDiscountHtml();
    } else {
      Header = this.getStandardLayoutText();
    }

    return (
      <div
        data-testid="paymentFlowFormUpsellContainer"
        className={css.upsellFormContainer}
      >
        <h3 data-testid="premiumLogo" className={css.premiumLogo}>
          {getLocalizedText(PREMIUM).toUpperCase()}
        </h3>
        {isCustomSourceEnabled(location) && (
          <img
            data-testid="customSourceLogo"
            className={css.customSourceLogo}
            src={`${RADIOMILL_CMS_CUSTOM_ASSET_URL}${source}.png`}
          />
        )}
        {Header}
        <table>
          <tbody>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(UPSELL_COMMERCIAL_FREE_SPORTS)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(UPSELL_COMMERCIAL_FREE_NEWS)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(UPSELL_COMMERCIAL_FREE_MUSIC)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(UPSELL_COMMERCIAL_FREE_AUDIOBOOKS)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(UPSELL_LESS_ADS)}
              </td>
            </tr>
          </tbody>
        </table>
        <span className={css.footnoteText}>
          {this.isOneTimeDefaultCoupon() &&
            `*${getLocalizedText(ONE_TIME_DEFAULT_COUPON_FOOTNOTE_TEXT)}`}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guideItem: get(state.subscriptionFlow, 'profile.item.guideItem'),
    coupon: selectCoupon(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    fetchProfileIfInQueryByLocation: (location) =>
      dispatch(fetchProfileIfInQueryByLocation(location)),
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UpsellCard);
