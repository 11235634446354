import classnames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { SOURCE_ONAIR } from '../../constants/sourceQueryParams';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectBreakpoint } from '../../selectors/app';
import breakpoints from '../../styles/breakpoints';
import generateOriginLabelFromQuery from '../../utils/generateOriginLabelFromQuery';
import UpsellCard from '../subscription/UpsellCard';
import UpsellCardOnAir from '../subscription/UpsellCardOnAir';
import PaymentFlowProgressBar from './PaymentFlowProgressBar';
import css from './payment-flow-form-layout.module.scss';
import getProgressBarSteps from './utils/getProgressBarSteps';

export class PaymentFlowFormLayout extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    isNoPaymentFlow: PropTypes.bool,
    selectedProduct: PropTypes.object,
    // layoutProps
    alwaysShowUpsellTable: PropTypes.bool.isRequired,
    isPaymentFlow: PropTypes.bool,
    isPairingFlow: PropTypes.bool,
    isPartnerFlow: PropTypes.bool,
    formContainerClassName: PropTypes.string,
    resetFormPadding: PropTypes.bool,
    viewType: PropTypes.string.isRequired,
    subFlowType: PropTypes.string,
    showProgressBar: PropTypes.bool.isRequired,
    // mapStateToProps
    breakpoint: PropTypes.number.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  static defaultProps = {
    formContainerClassName: '',
  };

  render() {
    const {
      children,
      isPaymentFlow,
      isPairingFlow,
      isPartnerFlow,
      alwaysShowUpsellTable,
      isNoPaymentFlow,
      selectedProduct,
      formContainerClassName,
      resetFormPadding,
      viewType,
      subFlowType,
      showProgressBar,
      breakpoint, // mapStateToProps
    } = this.props;

    const { getLocalizedText, location } = this.context;
    const source = generateOriginLabelFromQuery(location);
    const isOnAirFlow = source === SOURCE_ONAIR;

    const shouldShowUpsellCard =
      !isPairingFlow &&
      isPaymentFlow &&
      (alwaysShowUpsellTable || breakpoint >= breakpoints.SMALL);
    const noPaddingClassName = { [css.noPadding]: resetFormPadding };

    const progressBarSteps = getProgressBarSteps(
      showProgressBar,
      isPaymentFlow,
      viewType,
      subFlowType,
      getLocalizedText,
      isPairingFlow,
    );

    return (
      <div
        data-testid="paymentFlowFormLayoutContainer"
        className={classnames(
          css.formLayoutContainer,
          {
            [css.isPairingFlow]: isPairingFlow,
          },
          noPaddingClassName,
        )}
      >
        <div
          data-testid="paymentFlowFormInnerLayoutContainer"
          className={css.formLayoutInnerContainer}
        >
          <div
            data-testid="paymentFlowFormContainer"
            className={
              isPaymentFlow
                ? css.innerFormLayoutContainerColumn
                : css.innerFormLayoutContainerRow
            }
          >
            {!isOnAirFlow && shouldShowUpsellCard && (
              <UpsellCard
                isNoPaymentFlow={isNoPaymentFlow}
                selectedProduct={selectedProduct}
              />
            )}
            {isOnAirFlow && <UpsellCardOnAir />}
            <div
              data-testid="paymentFlowForm"
              className={classnames(
                css.formContainer,
                noPaddingClassName,
                formContainerClassName,
                {
                  [css.isPairingFlow]: isPairingFlow,
                  [css.isOnlyAuthFlow]: !isPartnerFlow && !isPairingFlow,
                },
              )}
            >
              {progressBarSteps && (
                <PaymentFlowProgressBar steps={progressBarSteps} />
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    breakpoint: selectBreakpoint(state),
    viewType: state.loginDetails?.viewType,
  };
}

export default connect(mapStateToProps)(PaymentFlowFormLayout);
