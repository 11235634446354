/* eslint-disable max-len */
export const SUBSCRIBE_PAYMENT_FLOW_PROGRESS_BAR_STEP_LABEL =
  'subscribe.payment.flow.progress.bar.step.label';

export const SUBSCRIBE_PAYMENT_FLOW_FORM_LAYOUT_HEADER =
  'subscribe.payment.flow.form.layout.header';
export const SUBSCRIBE_PAYMENT_FLOW_FORM_LAYOUT_SUB_HEADER =
  'subscribe.payment.flow.form.layout.sub.header';

export const SUBSCRIBE_PAYMENT_COMPLETE_TITLE =
  'subscribe.payment.complete.title';
export const SUBSCRIBE_PAYMENT_COMPLETE_TITLE_NP =
  'subscribe.payment.complete.title.np';
export const SUBSCRIBE_PAYMENT_COMPLETE_TITLE_NP_MONTH =
  'subscribe.payment.complete.title.np.month';
export const SUBSCRIBE_PAYMENT_COMPLETE_TITLE_FREE =
  'subscribe.payment.complete.title.free';
export const SUBSCRIBE_PAYMENT_COMPLETE_SUBTITLE_NP_MONTH_DISC =
  'subscribe.payment.complete.subtitle.np.month.disc';
export const SUBSCRIBE_PAYMENT_COMPLETE_SUBTITLE_NP_MONTH =
  'subscribe.payment.complete.subtitle.np.month';
export const SUBSCRIBE_PAYMENT_COMPLETE_SUBTITLE_NP_YEAR_DISC =
  'subscribe.payment.complete.subtitle.np.year.disc';
export const SUBSCRIBE_PAYMENT_COMPLETE_SUBTITLE_NP_YEAR =
  'subscribe.payment.complete.subtitle.np.year';
export const SUBSCRIBE_PAYMENT_COMPLETE_SUBTITLE_FREE =
  'subscribe.payment.complete.subtitle.free';
export const SUBSCRIBE_PAYMENT_COMPLETE_TITLE_PROFILE =
  'subscribe.payment.complete.title.profile';
export const SUBSCRIBE_PAYMENT_COMPLETE_DOWNLOAD_DESKTOP_APP_TITLE =
  'subscribe.payment.complete.download.desktop.app.title';

export const RENEW_PAYMENT_FLOW_FORM_LAYOUT_HEADER =
  'renew.payment.flow.form.layout.header';
export const RENEW_PAYMENT_FLOW_FORM_LAYOUT_SUB_HEADER =
  'renew.payment.flow.form.layout.sub.header';

export const UPDATE_PAYMENT_FLOW_FORM_LAYOUT_HEADER =
  'update.payment.flow.form.layout.header';
export const UPDATE_PAYMENT_FLOW_FORM_LAYOUT_SUB_HEADER =
  'update.payment.flow.form.layout.sub.header';

export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_LINE2 =
  'payment.flow.form.layout.upsell.line2';
export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_LINE3 =
  'payment.flow.form.layout.upsell.line3';

export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_YEARLY =
  'payment.flow.form.layout.upsell.yearly';
export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_YEARLY_NO_TRIAL =
  'payment.flow.form.layout.upsell.yearly.no.trial';
export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_MONTHLY =
  'payment.flow.form.layout.upsell.monthly';
export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_MONTHLY_NO_TRIAL =
  'payment.flow.form.layout.upsell.monthly.no.trial';

export const PAYMENT_FLOW_FORM_LAYOUT_UPSELL_SUBTITLE =
  'payment.flow.form.layout.upsell.subtitle';
export const PAYMENT_FLOW_FORM_LAYOUT_ALT_PAYMENT_TEXT =
  'payment.flow.form.layout.alternate.payment.text';

export const REQUIRED_PAYMENT_FIELD = 'payment.required.field';
export const INVALID_PAYMENT_FIELD = 'payment.invalid.field';

export const COUNTRY_PAYMENT_FIELD = 'payment.country.field';
export const STATE_PAYMENT_FIELD = 'payment.state.field';

export const PAYMENT_INFORMATION_FORM_SUBMIT_BUTTON =
  'payment.information.form.submit.button';
