/* SOURCE QUERY PARAMS source= */
/*
 Used to indicate the source is coming from the mobileupsell
 */
export const SOURCE_MOBILE_UPSELL = 'mobileupsell';
/*
 Used to indicate the source is coming from the partnerupsell
 */
export const SOURCE_PARTNER_UPSELL = 'partnerupsell';
/*
 Used to indicate the source is coming from the onair flow
 */
export const SOURCE_ONAIR = 'onair';
