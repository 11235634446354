import interpolateComponents from 'interpolate-components';
import { useContext } from 'react';

import cssVariables from 'src/common/styles/variables';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';

import { LINE_BREAK_INTERPOLATE } from '../../constants/localizations/shared';
import {
  UPSELL_ONAIR_ACCESS,
  UPSELL_ONAIR_ANALYTICS,
  UPSELL_ONAIR_DEVICES,
  UPSELL_ONAIR_TITLE,
} from '../../constants/localizations/upsell';

import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import css from './upsell-card.module.scss';

// exported for testing
export const svgProps = {
  width: '13px',
  height: '10px',
  viewBox: '0 0 13 11',
  fill: cssVariables['--tunein-coral'],
};

export default function UpsellCardOnAir() {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const title = getLocalizedText(UPSELL_ONAIR_TITLE).replace(
    /\{0\}/gi,
    LINE_BREAK_INTERPOLATE,
  );

  return (
    <div
      data-testid="paymentFlowFormUpsellOnAirContainer"
      className={css.upsellFormContainer}
    >
      <div
        data-testid="paymentFlowFormUpsellOnAirImage"
        className={css.onAirImage}
      />
      <div
        data-testid="paymentFlowFormUpsellOnAirTitleContainer"
        className={css.topMargin}
      >
        <span
          data-testid="paymentFlowFormUpsellOnAirTitle"
          className={css.upsellTitle}
        >
          {interpolateComponents({
            mixedString: title,
            components: {
              linebreak: <br />,
            },
          })}
        </span>
      </div>
      <table data-testid="paymentFlowFormUpsellOnAirFeatureList">
        <tbody>
          <tr>
            <td data-testid="paymentFlowFormUpsellOnAirFeatureCheckmark1">
              <Checkmark {...svgProps} />
            </td>
            <td
              data-testid="paymentFlowFormUpsellOnAirFeatureAccess"
              className={css.tableText}
            >
              {getLocalizedText(UPSELL_ONAIR_ACCESS)}
            </td>
          </tr>
          <tr>
            <td data-testid="paymentFlowFormUpsellOnAirFeatureCheckmark2">
              <Checkmark {...svgProps} />
            </td>
            <td
              data-testid="paymentFlowFormUpsellOnAirFeatureDevices"
              className={css.tableText}
            >
              {getLocalizedText(UPSELL_ONAIR_DEVICES)}
            </td>
          </tr>
          <tr>
            <td data-testid="paymentFlowFormUpsellOnAirFeatureCheckmark3">
              <Checkmark {...svgProps} />
            </td>
            <td
              data-testid="paymentFlowFormUpsellOnAirFeatureAnalytics"
              className={css.tableText}
            >
              {getLocalizedText(UPSELL_ONAIR_ANALYTICS)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
